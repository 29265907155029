import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

export default {
  init: function(hubName) {
    var url = '';
    var windowUrl = window.location.href.split('/');
    
    for (var i = 0; i < 3; i++) {
      url += `${windowUrl[i]}/`;
    }
    url += hubName;

    var hub = new HubConnectionBuilder()
      .withUrl(url)
      .configureLogging(LogLevel.Information)
      .build();

    hub.start();
    return hub;
  }
}