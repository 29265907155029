<template>
  <div id="app">
    <div v-if="!joined" style="width: 100%; display: flex; flex-direction: column; align-items: center">
      <div style="display: flex; align-items: center">
        <my-input :value.sync="playerName" style="margin-right: 5px" />
        <my-button @click="createRoomClicked">Create Room</my-button>
      </div>
      <div v-if="showError" class="error">Please enter your name</div>

      <div class="room-block">
        <div class="room-title" v-if="roomList.length == 0">No Available Rooms</div>
        <div class="room-title" v-else>Rooms Available</div>
        <div v-for="(r, i) in roomList" :key="i" class="room-names">
          <div style="flex-grow: 1">{{ r.name }}</div>
          <my-button @click="joinClicked(r)" :disabled="r['isFull']">{{ r['isFull'] ? 'Full' : 'Join' }}</my-button>
        </div>
      </div>
    </div>

    <div v-else>
      <my-button @click="quitClicked" buttonColor="red" textColor="white">Quit</my-button>
      <!-- <div v-if="!gameEnd">Game is on the way!</div>
      <div v-else-if="winnerName">Congratulations {{ winnerName }}, you win!</div>
      <div v-else>The game ended in draw</div> -->
      <TicTacToeBoard @gameOver="gameOver" />
      <my-button v-if="gameEnd" @click="playAgainClicked" :disabled="!gameEnd || !canPlayAgain">Play Again</my-button>
      {{ playAgainMsg }}
      <!-- <div class="chat-icon"></div>
      <div class="chat-block"></div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import TicTacToeBoard from '@/components/TicTacToeBoard.vue';
import MyButton from '@/components/MyButton.vue';
import MyInput from '@/components/MyInput.vue';

export default {
  name: 'App',
  components: {
    TicTacToeBoard, MyButton, MyInput
  },
  data: function() {
    return {
      roomOwner: false,
      showError: false,

      roomList: [],

      joined: false,

      gameEnd: false,
      winnerName: '',
      playerName: '',

      playAgainMsg: '',
      canPlayAgain: true,
      waitingResponse: false
    }
  },
  methods: {
    createRoomClicked: function() {
      this.showError = false;
      if (this.playerName) {
        this.roomOwner = true;
        this.$store.state.myself = { name: this.playerName, connection: this.$store.state.connectionID };
        this.joined = true;
        this.playAgainMsg = '';
        this.gameEnd = false;
        this.canPlayAgain = true;
        this.$axios.post('/Updates/SendUpdate', { method: 'RoomCreated', data: this.$store.state.myself });
        this.$nextTick(() => {
          this.$bus.$emit('gameStarted', true);
        });
      } else {
        this.showError = true;
      }
    },
    roomCreated: function(e) {
      e['isFull'] = false;
      this.roomList.splice(0, 0, e);
    },

    joinClicked: function(e) {
      if (this.playerName) {
        this.$store.state.myself = { name: this.playerName, connection: this.$store.state.connectionID };
        this.joined = true;
        this.$store.state.opponent = e;
        this.gameEnd = false;
        this.canPlayAgain = true;
        this.playAgainMsg = '';
        // this.$axios.post(`/Updates/UserJoin?id=${e['connection']}`, this.$store.state.myself);
        this.$axios.post('/Updates/SendUpdate', { method: 'UserJoined', id: e['connection'], data: this.$store.state.myself });
        this.$axios.post('/Updates/SendUpdate', { method: 'UpdateRoomAvailability', data: { connection: e['connection'], full: true } });
        this.$nextTick(() => {
          this.$bus.$emit('gameStarted', false);
        });
      } else {
        this.showError = true;
      }
    },
    gameOver: function(name) {
      this.gameEnd = true;
    },
    playAgainClicked: function() {
      if (this.playAgainMsg) {
        this.playAgainMsg = '';
        this.$axios.post('/Updates/SendUpdate', { method: 'OpponentAccept', id: this.$store.state.opponent['connection'] });
        this.$bus.$emit('resetBoard', false);
      } else {
        this.$axios.post('/Updates/SendUpdate', { method: 'OpponentPlayAgain', id: this.$store.state.opponent['connection'] });
        this.$store.state.signal.on('OpponentAccept', this.opponentAccept);
        this.$bus.$emit('resetBoard', true);
      }
      this.gameEnd = false;
      this.winnerName = '';
    },
    opponentPlayAgain: function() {
      this.playAgainMsg = 'Opponent wants to play again';
    },
    quitClicked: async function() {
      var quit = await Swal.fire({
        title: 'Are you sure you want to quit?',
        text: 'Opponent will automatically win if you quit.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Quit!',
        confirmButtonColor: 'red'
      });

      if (quit['isConfirmed']) {
        if (this.$store.state.opponent) {
          this.$axios.post('/Updates/SendUpdate', { method: 'OpponentQuit', id: this.$store.state.opponent['connection'] });
        }
        
        if (this.roomOwner) {
          var myRoom = this.roomList.filter((r) => { return r['connection'] == this.$store.state.myself['connection'] });
          if (myRoom.length > 0) {
            var index = this.roomList.indexOf(myRoom[0]);
            this.roomList.splice(index, 1);
            this.$axios.post('/Updates/SendUpdate', { method: 'RoomDelete', data: this.$store.state.myself });
          }
          this.roomOwner = false;
        } else {
          var ownerRoom = this.roomList.filter((r) => { return r['connection'] == this.$store.state.opponent['connection'] });
          if (ownerRoom.length > 0) {
            this.$axios.post('/Updates/SendUpdate', { method: 'UpdateRoomAvailability', data: { connection: this.$store.state.opponent['connection'], full: false } });
          }
        }
        
        this.$store.state.opponent = null;
        this.joined = false;
      }
    },
    roomDelete: function(e) {
      var deleteRoom = this.roomList.filter((r) => { return r['connection'] == e['connection'] });
      if (deleteRoom.length > 0) {
        var index = this.roomList.indexOf(deleteRoom[0]);
        this.roomList.splice(index, 1);
      }
    },
    updateRoomAvailability: function(e) {
      var fullRoom = this.roomList.filter((r) => { return r['connection'] == e['connection'] });
      if (fullRoom.length > 0) {
        fullRoom[0]['isFull'] = e['full'];
      }
    },
    opponentQuit: function() {
      this.playAgainMsg = 'Opponent has exit the room';
      this.canPlayAgain = false;
      this.gameEnd = true;
    },
    userJoined: function(e) {
      this.playAgainMsg = '';
      this.canPlayAgain = true;
      this.gameEnd = false;
    },
    mybuttonClicked: function() {
      console.log('testing');
    }
  },
  mounted() {
    this.$store.commit('startSignal');

    this.$store.state.signal.on('RoomCreated', this.roomCreated);
    this.$store.state.signal.on('OpponentPlayAgain', this.opponentPlayAgain);
    this.$store.state.signal.on('RoomDelete', this.roomDelete);
    this.$store.state.signal.on('UpdateRoomAvailability', this.updateRoomAvailability);
    this.$store.state.signal.on('OpponentQuit', this.opponentQuit);
    this.$store.state.signal.on('UserJoined', this.userJoined );
  },
  destroyed() {
    this.$store.state.signal.off('RoomCreated');
    this.$store.state.signal.off('OpponentPlayAgain');
    this.$store.state.signal.off('RoomDelete');
    this.$store.state.signal.off('UpdateRoomAvailability');
    this.$store.state.signal.off('OpponentQuit');
    this.$store.state.signal.off('UserJoined');
  }
}
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  color: red;
}

.room-block {
  border: 1px solid black;
  padding: 5px;
  margin-top: 10px;
  min-width: 100px;
  max-width: 400px;
  width: 95%;
}

.room-names {
  border: 1px solid black;
  display: flex;
  margin: 5px 0;
  padding: 5px;
  align-items: center;
}

.room-title {
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}
</style>

<style>
body {
  margin: 0;
  padding: 5px;
  font-family: 'Fredericka the Great', cursive;
}

input {
  font-family: 'Fredericka the Great', cursive;
}
</style>
