<template>
  <div class="input-main">
    <input type="text" @focus="inputFocus" @blur="inputFocusOut" v-model="inputValue">
    <div class="placeholder" :style="{ bottom: placeholderBottom, fontSize: placeholderFont,
      fontWeight: this.placeholderBottom == '5px' ? 'normal' : 'bold' }">{{ placeholder }}</div>
    <div class="bottom-border" :style="{ maxWidth: showBottomBorder ? '100%' : '0px' }"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      showBottomBorder: false,
      placeholderBottom: '5px',
      placeholderFont: '0.9em',
      inputValue: ''
    }
  },
  props: {
    placeholder: { type: String, default: 'Placeholder' },
    value: { type: String, default: '' },
  },
  methods: {
    inputFocus: function(e) {
      this.showBottomBorder = true;
      this.placeholderFont = '0.7em';
      this.placeholderBottom = '20px';
    },
    inputFocusOut: function(e) {
      this.showBottomBorder = false;
      if (!this.inputValue) {
        this.placeholderFont = '0.9em';
        this.placeholderBottom = '5px';
      }
    }
  },
  mounted() {
    this.inputValue = this.value;
    if (this.inputValue) {
      this.inputFocus();
      this.showBottomBorder = false;
    }
  },
  watch: {
    inputValue: function(val) {
      this.$emit('update:value', val);
    }
  }
}
</script>

<style scoped>
.input-main {
  border-style: solid;
  border-color: rgba(150,150,150,0.5);
  border-width: 0 0 1px 0;
  position: relative;
  display: flex;
  width: 100%;
  height: 35px;
}

.input-main input {
  border: none;
  outline: none;
  padding: 5px;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.bottom-border {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: red;
  transition: 0.3s;
}

.placeholder {
  position: absolute;
  pointer-events: none;
  left: 5px;
  font-size: 0.9em;
  transition: 0.3s;
}
</style>