import Vue from 'vue';
import Vuex from 'vuex';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signal: null,
    connectionID: '',
    myself: null,
    opponent: null
  },
  getters: {
  },
  mutations: {
    async startSignal (state) {
      if (!state.signal) {
        var url = '';
        var windowUrl = window.location.href.split('/');
        
        for (var i = 0; i < 3; i++) {
          url += `${windowUrl[i]}/`;
        }
        url += 'update';

        state.signal = new HubConnectionBuilder()
          .withUrl(url)
          .configureLogging(LogLevel.None)
          .build();

        await state.signal.start();
        state.connectionID = state.signal['connection']['connectionId'];
        console.log(`<><><><><>`)
        console.log(`My connection id is: ${state.connectionID}`);
        console.log(`<><><><><>`)
      }
    }
  }
})