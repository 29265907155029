<template>
  <div class="button-main" @click="clicked" @mousedown="mouseDown" @mouseup="mouseOut" @mouseout="mouseOut"
    @mouseenter="mouseEnter" @mouseleave="mouseLeave"
    :style="{ backgroundColor: pButtonColor, color: pTextColor, pointerEvents: disabled ? 'none' : 'unset',
    boxShadow: disabled ? '0px 0px 0px 0px black' : '0px 3px 3px 0px black' }">
    <slot></slot>
    <div v-if="disabled" class="disabled"></div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      pButtonColor: '',
      pTextColor: ''
    }
  },
  props: {
    text: { type: String, default: 'Button' },
    buttonColor: { type: String, default: '#7fe0ff' },
    textColor: { type: String, default: 'black' },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    clicked: function() {
      this.$emit('click');
    },
    mouseDown: function(e) {
      e.target.style.boxShadow = '0px 0px 0px 0px black';
      e.target.style.transform = 'scale(0.95)';
    },
    mouseOut: function(e) {
      e.target.style.boxShadow = '0px 3px 3px 0px black';
      e.target.style.transform = 'scale(1)';
    },
    mouseEnter: function(e) {
      this.pButtonColor = this.textColor;
      this.pTextColor = this.buttonColor;
    }, 
    mouseLeave: function(e) {
      this.pButtonColor = this.buttonColor;
      this.pTextColor = this.textColor;
    }
  },
  mounted() {
    this.pButtonColor = this.buttonColor;
    this.pTextColor = this.textColor;
  },
  watch: {
    buttonColor: function(val) {
      this.pButtonColor = val;
    },
    textColor: function(val) {
      this.pTextColor = val;
    }
  }
}
</script>

<style scoped>
.button-main {
  width: fit-content;
  border: 1px solid gray;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  padding: 5px;
  user-select: none;
  transition: 0.1s;
  box-shadow: 0px 3px 3px 0px black;
  font-size: 0.9em;
  white-space: nowrap;
  position: relative;
}

.disabled {
  background-color: rgba(64,64,64,0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>