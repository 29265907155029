import Vue from 'vue'
import App from './App.vue'
import Bus from '@/js/BusEvent.js'
import Axios from 'axios'
import Signal from '@/js/signalRHub.js'
import vuex from '@/js/vuex.js'

Vue.config.productionTip = false

Vue.prototype.$bus = Bus
Vue.prototype.$axios = Axios
Vue.prototype.$signal = Signal

new Vue({
  render: h => h(App),
  store: vuex
}).$mount('#app')
