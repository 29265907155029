<template>
  <div class="board-main" id="mainBoard">
    <div class="msgs" v-if="opponentName">Playing with {{ opponentName }}</div>
    <div class="msgs">{{ message }}</div>
    <div v-for="i in 3" :key="i" class="row">
      <div v-for="j in 3" :key="j" class="box" @mouseenter="mousehover(i, j)" @mouseleave="mouseleave" @click="placeIcon(i, j)">
        <img v-if="player1Icons.includes(`x${i}y${j}`)"
          src="https://img.icons8.com/fluent-systems-filled/50/000000/x.png" alt="">
        <img v-else-if="player2Icons.includes(`x${i}y${j}`)"
          src="https://img.icons8.com/ios-filled/50/000000/circled.png" alt="">
        <img v-else-if="isPlayer1 && hoverID == `x${i}y${j}`" style="opacity: 0.3"
          src="https://img.icons8.com/fluent-systems-filled/50/000000/x.png" alt="">
        <img v-else-if="hoverID == `x${i}y${j}`" style="opacity: 0.3"
          src="https://img.icons8.com/ios-filled/50/000000/circled.png" alt="">
      </div>
    </div>
    <div id="w1" class="win-line" :style="{ top: `${allMidPoint + 55}px`, left: '2.5%', maxWidth: '0', width: '95%', height: '5px' }"></div>
    <div id="w2" class="win-line" :style="{ top: `${allMidPoint + height + 57}px`, left: '2.5%', maxWidth: '0', width: '95%', height: '5px' }"></div>
    <div id="w3" class="win-line" :style="{ top: `${allMidPoint + (height * 2) + 59}px`, left: '2.5%', maxWidth: '0', width: '95%', height: '5px' }"></div>

    <div id="w4" class="win-line" :style="{ left: `${allMidPoint}px`, top: 'calc(2.5% + 55px)', height: '95%', maxHeight: '0', width: '5px' }"></div>
    <div id="w5" class="win-line" :style="{ left: `${allMidPoint + height + 2}px`, top: 'calc(2.5% + 55px)', maxHeight: '0', height: '95%', width: '5px' }"></div>
    <div id="w6" class="win-line" :style="{ left: `${allMidPoint + (height * 2) + 4}px`, top: 'calc(2.5% + 55px)', maxHeight: '0', height: '95%', width: '5px' }"></div>

    <div id="w7" class="win-line" :style="{ left: `2%`, top: 'calc(2.5% + 55px)', height: `${xLength * 0.95}px`, maxHeight: '0', width: '5px',
      transform: 'rotate(-45deg)', transformOrigin: 'top' }"></div>
    <div id="w8" class="win-line" :style="{ right: '2%', top: 'calc(2.5% + 55px)', height: `${xLength * 0.95}px`, maxHeight: '0', width: '5px',
      transform: 'rotate(45deg)', transformOrigin: 'top' }"></div>

    <div v-if="disabled" class="gameover-block">
      {{ disabledMessage }}
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  components: {
  },
  data: function() {
    return {
      isPlayer1: true,
      hoverPlayer: false,
      showIcon: false,
      hoverID: '',
      player1Icons: [],
      player2Icons: [],
      winCombination: [
        { id: 'w1', comb: [ 'x1y1', 'x1y2', 'x1y3' ] },
        { id: 'w2', comb: [ 'x2y1', 'x2y2', 'x2y3' ] },
        { id: 'w3', comb: [ 'x3y1', 'x3y2', 'x3y3' ] },

        { id: 'w4', comb: [ 'x1y1', 'x2y1', 'x3y1' ] },
        { id: 'w5', comb: [ 'x1y2', 'x2y2', 'x3y2' ] },
        { id: 'w6', comb: [ 'x1y3', 'x2y3', 'x3y3' ] },

        { id: 'w7', comb: [ 'x1y1', 'x2y2', 'x3y3' ] },
        { id: 'w8', comb: [ 'x3y1', 'x2y2', 'x1y3' ] }
      ],
      allMidPoint: 0,
      height: 0,
      xLength: 0,
      disabled: false,
      disabledMessage: '',
      message: '',
      gameEnd: null,
      opponentName: ''
    }
  },
  props: {
  },
  methods: {
    calcBoxSize: function() {
      var maxSize = window.innerWidth < window.innerHeight ? (window.innerWidth - 50) / 3 : (window.innerHeight - 200) / 3;
      var boxes = document.getElementsByClassName('box');
      Array.from(boxes).forEach((b) => {
        b.style.height = `${maxSize}px`;
        b.style.width = `${maxSize}px`;
      });
    },

    mousehover: function(x, y) {
      this.hoverID = `x${x}y${y}`;
      this.$axios.post('/Updates/SendUpdate', { method: 'OpponentHoverIn', id: this.$store.state.opponent['connection'], data: { pos: this.hoverID } });
    },
    mouseleave: function(e) {
      this.hoverID = '';
      this.$axios.post('/Updates/SendUpdate', { method: 'OpponentHoverOut', id: this.$store.state.opponent['connection'], data: { pos: '' } });
    },
    placeIcon: function(x, y, opp) {
      if (this.player1Icons.includes(`x${x}y${y}`) || this.player2Icons.includes(`x${x}y${y}`)) {
        return;
      }
      if (opp) { // From opponent
        this.isPlayer1 = !this.hoverPlayer;
        if (this.isPlayer1) {
          this.player2Icons.push(`x${x}y${y}`);
        } else {
          this.player1Icons.push(`x${x}y${y}`);
        }
      } else { // From click
        if (this.isPlayer1) {
          this.player1Icons.push(`x${x}y${y}`);
        } else {
          this.player2Icons.push(`x${x}y${y}`);
        }

        this.disabled = true;
        this.message = `${this.opponentName}'s turn`;

        this.checkIfWin(x, y);
        this.$axios.post('/Updates/SendUpdate', { method: 'OpponentPlaced', id: this.$store.state.opponent['connection'], data: {
          'x': x,
          'y': y,
          'winner': this.gameEnd
        } });
      }
    },
    checkIfWin: function(x, y) {
      for (var i = 0; i < this.winCombination.length; i++) {
        var wc = this.winCombination[i];

        var win1 = 0;
        var win2 = 0;

        wc['comb'].forEach((c) => {
          if (this.player1Icons.includes(c)) {
            win1++;
          }
          if (this.player2Icons.includes(c)) {
            win2++;
          }
        });

        if (win1 > 2) {
          if (this.isPlayer1) {
            this.message = `Congratulations! You win!!`;
            this.gameEnd = this.$store.state.myself['name'];
          } else {
            this.message = `${this.opponentName} win!`;
            this.gameEnd = this.opponentName;
          }
        } else if (win2 > 2) {
          if (this.isPlayer1) {
            this.message = `${this.opponentName} win!`;
            this.gameEnd = this.opponentName;
          } else {
            this.message = `Congratulations! You win!!`;
            this.gameEnd = this.$store.state.myself['name'];
          }
        }

        if (win1 > 2 || win2 > 2) {
          this.disabled = true;
          this.drawWinLine(wc['id']);
          this.gameEnd += `|${wc['id']}`;
          this.$emit('gameOver');
          break;
        }
      }
    },
    drawWinLine: function(line) {
      var el = document.getElementById(line);
      el.style.display = 'flex';
      // setTimeout(() => {
        if (line == 'w1' || line == 'w2' || line == 'w3') {
          el.style.maxWidth = '95%';
        } else if (line == 'w4' || line == 'w5' || line == 'w6') {
          el.style.maxHeight = 'calc(95% - 55px)';
        } else if (line == 'w7' || line == 'w8') {
          el.style.maxHeight = `${this.xLength * 0.95}px`;
        }
      // }, 50);
    },

    resetBoard: function(disable) {
      this.disabled = disable;
      this.player1Icons = [];
      this.player2Icons = [];
      this.gameEnd = null;
      this.message = disable ? 'Waiting response from opponent' : 'Your turn';
      this.winCombination.forEach((line) => {
        var el = document.getElementById(line['id']);
        if (line['id'] == 'w1' || line['id'] == 'w2' || line['id'] == 'w3') {
          el.style.maxWidth = '0';
        } else if (line['id'] == 'w4' || line['id'] == 'w5' || line['id'] == 'w6') {
          el.style.maxHeight = '0';
        } else if (line['id'] == 'w7' || line['id'] == 'w8') {
          el.style.maxHeight = `0`;
        }
      });
    },
    gameStarted: function(e) {
      this.isPlayer1 = e;
      this.hoverPlayer = !e;

      if (!e) {
        this.disabled = true;
        this.opponentName = this.$store.state.opponent['name'];
        this.message = `${this.opponentName}'s turn`;
        return;
      }

      this.message = 'Waiting for opponent';
      this.disabled = true;
    },
    userJoined: function(e) {
      this.$store.state.opponent = e;

      this.resetBoard(false);
      this.opponentName = e['name'];

    },
    opponentHover: function(type, pos) {
      if (type == 'in') {
        this.isPlayer1 = this.hoverPlayer;
        this.hoverID = pos;
      } else {
        this.isPlayer1 = !this.hoverPlayer;
        this.hoverID = '';
      }
    },
    opponentPlaced: function(e) {
      this.placeIcon(e['x'], e['y'], true);
      if (e['winner']) {
        this.disabled = true;
        this.message = `${this.opponentName} win!`;
        this.gameEnd = e['winner'];
        this.drawWinLine(e['winner'].split('|')[1]);
        this.$emit('gameOver');
      } else {  
        this.disabled = false;
        this.message = 'Your turn';
      }
    },
    opponentAccept: function() {
      this.message = `${this.opponentName}'s turn`;
      this.$store.state.signal.off('OpponentAccept');
    },
    opponentQuit: function() {
      if (!this.gameEnd) {
        this.message = 'Opponent quit! You win!!';
      }
      this.disabled = true;
    }
  },
  mounted() {
    this.calcBoxSize();
    window.addEventListener('resize', (e) => {
      this.calcBoxSize();
    });

    var board = document.getElementById('mainBoard');
    var totalHeight = board.getBoundingClientRect().width;
    this.height = (board.getBoundingClientRect().width - 8)/ 3
    this.allMidPoint = (board.getBoundingClientRect().width - 8) / 6;
    this.xLength = Math.sqrt((Math.pow(totalHeight, 2) + Math.pow(totalHeight, 2)));

    this.$bus.$on('resetBoard', (e) => { this.resetBoard(e) });
    this.$bus.$on('gameStarted', (e) => { this.gameStarted(e) });

    this.$store.state.signal.on('UserJoined', this.userJoined );
    this.$store.state.signal.on('OpponentHoverIn', (e) => { this.opponentHover('in', e['pos']) });
    this.$store.state.signal.on('OpponentHoverOut', (e) => { this.opponentHover('out', e['pos']) });
    this.$store.state.signal.on('OpponentPlaced', (e) => { this.opponentPlaced(e) });
    this.$store.state.signal.on('OpponentAccept', (e) => { this.opponentAccept(e) });
    this.$store.state.signal.on('OpponentQuit', (e) => { this.opponentQuit(e) });
  },
  watch: {
  },
  destroyed() {
    this.$store.state.signal.off('UserJoined');
    this.$store.state.signal.off('OpponentHoverIn');
    this.$store.state.signal.off('OpponentHoverOut');
    this.$store.state.signal.off('OpponentPlaced');
    this.$store.state.signal.off('OpponentAccept');
    this.$store.state.signal.off('OpponentQuit');
  }
}
</script>

<style scoped>
.board-main {
  padding: 2px 2px 0 0;
  width: fit-content;
  background-color: black;
  position: relative;
}

.msgs {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  position: relative;
  color: white;
  padding: 5px;
  font-weight: bold;
}

.board-main .win-line {
  position: absolute;
  background-color: lime;
  /* display: none; */
  transition: 1s;
}

.board-main .row {
  display: flex;
}

.board-main .row .box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-left: 2px;
  margin-bottom: 2px;
}

.board-main .gameover-block {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(128,128,128,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 0px 5px black;
  color: white;
  font-weight: bold;
}
</style>